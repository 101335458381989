import React, { useState } from 'react';
import './header.scss';
import HelpModal from './helpModal';

const Header = () => {
  const [helpShow, setHelpShow] = useState(false);

  const handleCloseHelp = event => {
    event.preventDefault();
    setHelpShow(false);
  };

  return (
    <>
      <header className="header-default">
        <div className="header-default__logo">
          <a href="/">
            <img
              height="50"
              src="/images/icf_foundation_logo.svg"
              alt="Soar"
              className="brand-image"
            />
          </a>
          <a
            className="coach-header-button coach-header-transparent coach-header-right coach-header-top"
            onClick={() => setHelpShow(true)}
          >
            Help
          </a>

          <div className="header-default__rectangle" />
        </div>
        <div className="pure-u-md-1-2">
          <p className="header-default__title">Coaches for Good</p>
          <div className="header-default__subtitle">Pro-Bono Coaching Available</div>
          <div className="header-default__content">
            Find the right coach & use your promo code to schedule free coaching sessions. Get
            &nbsp;started today.
          </div>
        </div>
        <div className="pure-u-md-1-2 header-default__image_section">
          <img
            src="/images/hero-illustration.png"
            className="header-default__image"
            alt="meeting"
          />
        </div>
      </header>

      <HelpModal show={helpShow} handleClose={e => handleCloseHelp(e)} />
    </>
  );
};

export default Header;
