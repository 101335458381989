import React from 'react';

function CoachEvangelistBadge(props) {
  if (props.evangelist === true) {
    return <span className="profile-avatar__badge badge">evangelist</span>;
  }
  return null;
}

export default CoachEvangelistBadge;
