import React from 'react';

import Header from '../shared/components/header';
// import BoxAudience from '../layout/components/boxAudience';
import Section from '../shared/components/section-primary';
import Card from '../shared/components/card-primary';
import Button from '../shared/components/button-primary';
import { HEADER } from './constants';
import './index.scss';

const { TITLE, SUBTITLE } = HEADER;

const headerParagraph = (
  <>
    <p>During these unprecedented times, the world needs coaches and coaching more than ever.</p>
    <p>
    ICF Coaches for Good is an initiative powered by the ICF Foundation to connect ICF Credential-holders 
    around the globe with leaders and organizations who are addressing the world’s most pressing 
    social challenges in significant and positive ways.
    </p>    
    <p>
      Coaches credentialed by the International Coaching Federation have met stringent education and
      experience requirements and have demonstrated a thorough understanding of the coaching
      competencies that set the standard in the profession. They also adhere to strict ethical
      guidelines as part of ICF’s mission to protect and serve coaching consumers.
    </p>

  </>
);

const textCard1 = (
  <div className="audience-icf__card-body">
    <strong>COACHES</strong>
    <br />
    <br />
    <span>
      Learn how you can make a social impact through pro bono coaching.
    </span>
  </div>
);
const buttonCard1 = (
  <Button link="https://coachfederation.org/coach-for-good" fluid>
    Learn More
  </Button>
);

const textCard2 = (
  <div className="audience-icf__card-body">
    <strong>ORGANIZATION LEADERS</strong>
    <br />
    <br />
    <span>Discover how non-profits, member associations, NGOs, and government agencies can
          apply for coaching through ICF Coaches for Good.</span>
  </div>
);
const buttonCard2 = (
  <Button link="/icf-form" fluid>
    Learn More
  </Button>
);

const textCard3 = (
  <div className="audience-icf__card-body">
    <strong>EXPLORE</strong>
    <br />
    <br />
    <span>Find an ICF Credentialed coach who can help you transform the world by navigating
    personal and professional challenges.</span>
  </div>
);
const buttonCard3 = (
  <Button link="/search" fluid>
    Learn More
</Button>
);

const AudienceICF = () => {
  return (
    <div className="audience-icf">
      <Header title={TITLE} subtitle={SUBTITLE} paragraph={headerParagraph} />
      <Section>
        <div className="grid">
          <div className="grid-item-1">
            <Card
              imagePath="/images/consultant@2x.png"
              imageLink="https://coachfederation.org/coach-for-good"
              body={textCard1}
              footer={buttonCard1}
            />
          </div>
          <div className="grid-item-2">
            <Card
              imagePath="/images/org@2x.png"
              imageLink="/icf-form"
              body={textCard2}
              footer={buttonCard2}
            />
          </div>
          <div className="grid-item-3">
            <Card
              imagePath="/images/client@2x.png"
              imageLink="/search"
              body={textCard3}
              footer={buttonCard3} />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default AudienceICF;
