import React from 'react';
import { Link } from 'react-router-dom';
import 'purecss/build/grids-min.css';
import 'purecss/build/grids-responsive-min.css';

import '../App.css';

import Results from './Results';

function App() {
  return (
    <div className="App">
      <header>
        <div id="navBar" className="navbar w-nav">
          <div className="pure-g">
            <div className="logo pure-u-1 pure-u-md-1-6">
              <div className="logo-line">
                <a href="/">
                  <img src="/images/icf-logo.png" alt="Soar" className="brand-image" />
                </a>
              </div>
              <div className="logo-line logo-powered">
                <a href="/">
                  <span>
                    Powered by
                    <img src="/images/soar-logo.png" alt="Soar" className="brand-image" />
                  </span>
                </a>
              </div>
            </div>
            <div className="pure-u-1 pure-u-md-5-6">
              <Link className="pure-menu-item" to="/search">
                Search
              </Link>
            </div>
          </div>
        </div>
      </header>
      <section>
        <div className="app-content">
          <div className="pure-g">
            <div className="pure-u-1">
              <h1 className="h1 hero-member">COVID-19 Volunteer Coaches</h1>
            </div>
          </div>
          <div className="pure-g">
            <div className="pure-u-1">
              <Results />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
