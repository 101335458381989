import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import 'purecss/build/grids-min.css';
import 'purecss/build/grids-responsive-min.css';

import AudienceICF from './audienceIcf';
import FormICF from './formIcf';
import FormICFRequestCode from './formIcfRequestCode';
import App from './oldHome';
import SearchPage from './searchCoaches';
import * as serviceWorker from './serviceWorker';
import './index.css';

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <Switch>
      <Route path="/" exact component={AudienceICF} />
      <Route path="/icf-form" exact component={FormICF} />
      <Route path="/icf-form-request-code" exact component={FormICFRequestCode} />
      <Route path="/search" exact component={SearchPage} />
      <Route path="/old" exact component={App} />
    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
