import React, { useState, useEffect } from 'react';
import cookies from 'browser-cookies';
import './Results.css';
import axios from 'axios';

import ProfileModal from '../profile/components/modal/ProfileModal';

import CoachEvangelistBadge from './CoachEvangelistBadge';
import { URL_ICF_CERT } from './constants';

const loader = <div className="loader">Loading...</div>;

const setCookie = (name, value) => {
  cookies.set(name, JSON.stringify(value), {
    expires: 1,
  });
};

const getCookie = name => {
  return cookies.get(name);
};

const App = () => {
  const [coachs, setCoachs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEnd, setIsLoadingEnd] = useState(true);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [languagesAPI, setLanguages] = useState([]);
  const [industryAPI, setIndustry] = useState([]);
  const [page, setPage] = useState(1);
  const [tmpPage, setTmpPage] = useState(1);
  let cookie = '';
  const [show, setShow] = useState(false);
  const [profile, setProfile] = useState([]);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);

  useEffect(() => {
    async function fetchIndustry() {
      const response = await axios.get(`https://api.soar.com/v1/Authority/icf/industries`);
      setIndustry(response.data);
      setCookie('coachOnlineIndustry', response.data);
    }

    async function fetchLanguage() {
      const response = await axios.get(`https://api.soar.com/v1/Authority/icf/languages`);
      setLanguages(response.data);
      setCookie('coachOnlineLanguage', response.data);
      setLoadingInitial(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    cookie = getCookie('coachOnlineIndustry');
    if (cookie) {
      setIndustry(JSON.parse(cookie));
      cookie = '';
    } else fetchIndustry();

    cookie = getCookie('coachOnlineLanguage');
    if (cookie) {
      setLanguages(JSON.parse(cookie));
      cookie = '';
      setLoadingInitial(false);
    } else fetchLanguage();
  }, [loadingInitial]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    async function fetchMyAPI() {
      if (page >= tmpPage && tmpPage > 1) return;
      if (page === 5) return;
      const response = await axios.get(
        `https://api.soar.com/v1/Search/coachDirectory?pageNum=${tmpPage}&itemsPerPage=18`
      );
      setCoachs([...coachs, response.data]);
      setPage(tmpPage);
      if (page === 1) {
        setIsLoading(false);
      } else {
        setIsLoadingEnd(false);
      }
      if (page === 5) {
        setIsLoadingEnd(false);
      }
    }
    if (!loadingInitial) fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingInitial, tmpPage]);

  function handleScroll() {
    if (
      window.innerHeight +
        Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) >=
      document.documentElement.offsetHeight
    ) {
      if (page === 5) {
        setIsLoadingEnd(false);
        return;
      }

      setIsLoadingEnd(true);
      setTmpPage(tmpPage + 1);
    }
  }

  const splitFunction = (string, obj) => {
    let tmp = '';
    let valueReturn = '';
    const split = string.split(',');
    for (let key = 0; key <= split.length; key++) {
      tmp = obj.find(options => options.code === split[key]);
      if (tmp) {
        valueReturn += `${tmp.name}, `;
      }
    }
    return valueReturn.slice(0, -2) || String(string).replace(/,/g, ', ');
  };

  const handleShow = async coachId => {
    setShow(true);
    setIsLoadingProfile(true);
    const { data: user } = await axios.get(`https://api.soar.com/v1/user/${coachId}`);
    setProfile(user);
    setIsLoadingProfile(false);
  };

  return (
    <>
      <ProfileModal
        show={show}
        profile={profile}
        isLoadingProfile={isLoadingProfile}
        splitFunction={splitFunction}
        languagesAPI={languagesAPI}
        industryAPI={industryAPI}
        handleClose={() => setShow(false)}
      />
      <div className="pure-g">
        {isLoading ? (
          ''
        ) : (
          <>
            {coachs.map(coach => (
              <>
                {coach.items.map(item => (
                  <div className="pure-u-1 pure-u-md-1-3 pure-u-lg-1-3 profile-block">
                    <div className="profile">
                      <div className="profile-content">
                        <div className="profile-avatar profile__avatar" wfd-id="212">
                          <a href={item.profileUrl} target="_blank" rel="noopener noreferrer">
                            <img
                              className="profile-avatar__picture"
                              src={
                                item.profilePictUrl && item.profilePictUrl !== ''
                                  ? item.profilePictUrl.replace('http://', 'https://')
                                  : `https://i1.wp.com/cdn.auth0.com/avatars/${
                                      item.name ? item.name.substring(0, 1).toLowerCase() : 's'
                                    }.png`
                              }
                              alt={item.name}
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src = 'https://i1.wp.com/cdn.auth0.com/avatars/s.png';
                              }}
                            />
                          </a>
                          {item.icfCertLevel && item.icfDigitalBadgeUrl && (
                            <a
                              href={item.icfDigitalBadgeUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="profile-avatar__icf-badge"
                            >
                              <img
                                width="40px"
                                src={URL_ICF_CERT[item.icfCertLevel]}
                                alt={item.icfCertLevel}
                              />
                            </a>
                          )}
                          <CoachEvangelistBadge evangelist={item.isEvangelist} />
                        </div>
                        <div>
                          <a href={item.profileUrl} target="_blank" rel="noopener noreferrer">
                            <div className="profile__fullname">{item.name}</div>
                            {item.icfIndustries && (
                              <div className="profile__description">
                                {splitFunction(item.icfIndustries, industryAPI)}
                              </div>
                            )}
                            {item.icfLanguages && (
                              <div className="profile__description">
                                {splitFunction(item.icfLanguages, languagesAPI)}
                              </div>
                            )}
                            <div className="profile__info" wfd-id="251">
                              <div className="profile__location" wfd-id="253">
                                <i className="profile__icon fas fa-map-marker-alt" />
                                <span className="profile__location-text" wfd-id="254">
                                  {item.locality ? `${item.locality}, ` : ''}
                                  {item.region ? `${item.region}, ` : ''}
                                  {item.countryCode ? item.countryCode : ''}
                                </span>
                              </div>
                            </div>
                          </a>
                          <div className="profile__button-group">
                            <a
                              className="profile__button button button-large button-blue button-rounded"
                              href={`${item.profileUrl}?isContact=true`}
                            >
                              <i className="fas fa-envelope" />
                              Contact
                            </a>
                            {item.schedulingIds && (
                              <a
                                className="button button-large button-purple button-rounded profile__button"
                                target="_blank"
                                href={`${item.profileUrl}?isSchedule=true`}
                              >
                                <i className="far fa-calendar-check" />
                                Schedule
                              </a>
                            )}
                            {item.isOnline === 1 && item.zoomUrl && (
                              <a
                                className="button button-large button-mandarine button-rounded profile__button"
                                href={`${item.zoomUrl}`}
                              >
                                <i className="fas fa-video" />
                                Immediate Appointment
                              </a>
                            )}
                            <a
                              className="button button-large button-mandarine button-rounded profile__button"
                              href="#"
                              onClick={() => handleShow(item.id)}
                            >
                              <i className="fas fa-video" />
                              View Profile
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ))}
          </>
        )}
        {isLoadingEnd && loader}
      </div>
    </>
  );
};

export default App;
