import React from 'react';
import PropTypes from 'prop-types';

import Section from '../section-primary';
import './header.scss';

const Header = ({ title, subtitle, paragraph, imagePath, logoPath, logoLink, showImage }) => (
  <Section>
    <header className="header">
      <div className="header__logo">
        <a href={logoLink}>
          <img height="50" src={logoPath} alt="SOAR" className="brand-image" />
        </a>
        <a className="coach-header-link coach-header-transparent coach-header-right coach-header-top"
          href="https://www.soar.com/icf-client-terms" target="_blank">
          ICF Client Terms and Conditions
          </a>
          <a className="coach-header-link coach-header-transparent coach-header-right coach-header-top"
          href="https://www.soar.com/soar-terms-of-use" target="_blank">
          Soar Terms of Service
          </a>
          
        <div className="header__rectangle" />
      </div>
      <div className={showImage && 'header__grid'}>
        <div className="header__grid__item-1">
          {title && <p className="header__title">{title}</p>}
          {subtitle && <div className="header__subtitle">{subtitle}</div>}
          {paragraph && <div className="header__content">{paragraph}</div>}
        </div>
        {showImage && (
          <div className="header__grid__item-2 header__image_section">
            <img src={imagePath} className="header__image" alt="meeting" />
          </div>
        )}
      </div>
    </header>
  </Section>
);

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  paragraph: PropTypes.string,
  logoPath: PropTypes.string,
  logoLink: PropTypes.string,
  imagePath: PropTypes.string,
  showImage: PropTypes.bool,
};

Header.defaultProps = {
  title: false,
  subtitle: false,
  paragraph: false,
  logoPath: '/images/icf_foundation_logo.svg',
  logoLink: '/',
  imagePath: '/images/hero-illustration.png',
  showImage: true,
};

export default Header;
